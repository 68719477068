/* Component Dependencies */
var dataLayerTemplate = require('templates/dataLayer.hbs');
var AriesComponent = require('libs/aries-component');
var PubSub = require('libs/pub-sub');
var _ = require('lodash');
var $ = require('jquery');
var Cookie = require('utils/cookie-ops');

PubSub.register(AriesComponent.extend({
  type: 'dataLayer',
  template: {
    'dataLayer': dataLayerTemplate
  },
  subscribe: {
    'ADD_TO_DATALAYER': 'extendDataLayer',
    'WINDOW_LOAD_EVENT': 'flushErrors',
    'DATALAYER_REFRESH': 'refresh',
    'DATALAYER_REFRESH_AKAMAI': 'mergeDataLayer'
  },
  bindEvents: function() {
    var _self = this;
    //Set mvp prequal FOC offer for show/click events in windown for monetate 
    window.preQualShowOfferEvent = function(){
        var eventType = "show";
        return _self.prequalFOCConfirmationAjaxCall(eventType);
    };
    window.preQualClickOfferEvent = function(){
        var eventType = "click";
        return _self.prequalFOCConfirmationAjaxCall(eventType);
    };

    if(!window.dataLayer.ensightenEnabled){
      $(document).on('mouseup.dataLayer',
        'a,button,li,input[type="submit"],input[type="image"],input[type="button"]',
        function(event) {
          _self.clickAnalytics(event, $(this));
          event.stopPropagation();
      });
      window.dataLayer.ensightenEnabled = true;
    }

    window.onload = function(event) {
      _self.pubsub.publish('WINDOW_LOAD_EVENT');
    }
  },
  mergeDataLayer: function _mergeDataLayer() {
    var _self = this;
    var oldDataLayerObject = window.dataLayer;
    _self.refresh(null,function(){
      window.dataLayer = Object.assign(oldDataLayerObject, window.dataLayer);
        var trackingInfo = {
          page_data_layer_ready: "true",
          timeout_hit: "true",
          tiles: window.makenComponents.resolvedComponentList
        };
        window._trackAnalytics(trackingInfo);

    });
  },
  
  /**
   * Add/update userEvents Array with Event information
   * @param {Object} data Element & event information
   */
  pushToDataLayer: function(data) {
    if (data && Object.prototype.toString.call(data).slice(8, -1).toLowerCase() === 'object') {
      window.dataLayer.userEvents = window.dataLayer.userEvents || [];
      window.dataLayer.userEvents.push(data);
    }
  },

  /**
   * Function used for making the flush error call
   */
  flushErrors: function(){
    var _self = this;
    if (_self.$el.data("invoke-flush")) {
      _self.makeAjaxCall({
        url: _self.$el.data("flush-url"),
        method: 'POST',
        data: JSON.stringify({'sessionToken': window.dataLayer.sessionId})
      });
    }
  },

  /**
   * Send Event info object to ensighten & data layer and create/udpate cookie
   * @param {Object} event Current user event object
   * @param {Object} ele Element which triggered the event
   */
  clickAnalytics: function(event, ele) {
    try {
      var dataAttr = {};
      for (var key in ele[0].dataset) {
        if (ele[0].dataset.hasOwnProperty(key)) {
          dataAttr[key] = ele[0].dataset[key];
        }
      }
      var eventInfo = {
        id: ele.attr('id') || '',
        class: ele.attr('class') || '',
        text: ele.text() || '',
        dataAttr: dataAttr,
        event_type: 'click',
        event_data: {
          timeStamp: event.timeStamp,
          metaKey: event.metaKey,
          namespace: event.namespace || '',
          pageX: event.pageX,
          pageY: event.pageY
        }
      };
      this.pushToDataLayer(eventInfo);
      Cookie.createCookie('lastUserEvent', JSON.stringify(eventInfo));
      window._trackAnalytics(eventInfo);
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Extend dataLayer object with data parameter properties
   * @param {Object} data Object with properties to add to dataLayer
   * @param {Object} eventData Ensighten event related data
   */
  extendDataLayer: function _extendDataLayer(data, eventData) {
    var _self = this;

    if (data && Object.prototype.toString.call(data).slice(8, -1).toLowerCase() === 'object') {
      _.extend(window.dataLayer, data);
    }

    if (eventData) {
      _self.triggerEnsighten(eventData);
    }
  },

  /**
   * Event data to be sent to Ensighten
   * @param {Object} eventData Ensighten event related data
   */
  triggerEnsighten: function _triggerEnsighten(eventData) {
    try {
      typeof window._trackAnalytics === 'function' && window._trackAnalytics(eventData);
    } catch(err) {
      console.error('Unable to trigger datalayer analytics call!');
    }
  },

  /**
   * This function triggers ajax call based on show/click events by monetate team 
   * @param eventType : show/click
   */
  prequalFOCConfirmationAjaxCall: function(eventType){
      var _self = this;
      var baseUrl = window.dataLayer.mvp_prequal_endpoint;
      var data = {};
      data.context = _self.getContextData();
      data.sessionToken = _self.getSessionToken();
      data.sourceURI = _self.getSourceURI();
      data.variation = _self.getVariation();
      data.context.success = true;
      data.context.eventType = eventType;
      data.context.eventInfo = window.mvpOffers.eventInfo;
      data.context.offerIdentifier = window.mvpOffers.offerIdentifier;
      _self.makeAjaxCall({
        url: baseUrl,
        method: 'POST',
        data: JSON.stringify(data),
        success : function(data){
          return ((data.component.data.eventId === "") ? false : true);
        },
        error: function() {
          return false;
        }
      });
  }
}));
