/**
 * Cookie operations
 *
 * @type {Object}
 */
module.exports = {
  /**
   * Create a new cookie value
   *
   * @param  {string} name    name of the cookie
   * @param  {value}  value   value of the cookie
   * @param  {days}   days    expiry in days
   */
  createCookie: function(name, value, days) {
    var expires;

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toGMTString();
    } else {
      expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
  },

  /**
   * Retrieve a cookie value
   *
   * @param  {string} name    name of the cookie
   * @return {string|null}         cookie value
   */
  readCookie: function(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  },

  /**
   * Delete a cookie
   *
   * @param  {string} name    name of the cookie
   */
  eraseCookie: function(name) {
    this.createCookie(name, '', -1);
  }
};
